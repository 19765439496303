var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mLocationID != null)?_c('BT-Blade-Items',{attrs:{"addBladeName":"stock-adjustments","bladesData":_vm.bladesData,"bladeName":"stock-items","canBreakdown":"","canCreate":false,"canEdit":false,"canSearchLocal":"","getParams":_vm.getParams,"getSelectBladeData":function (bladeData, item) { return { productID: item.productID, locationID: _vm.mLocationID, batchID: item.batchID } },"getSelectQuery":function (bladeData, item) { return { productID: item.productID, locationID: _vm.mLocationID, batchID: item.batchID } },"loading":_vm.isLoading,"navigation":"stock-items","refreshToggle":_vm.refreshToggle,"title":"Stocked Items","useServerFilters":"","useServerPagination":"","headers":[
        { text: 'Location', value: 'location.locationName', csv: true, title: 0, searchable: true },
        { text: 'Product', value: 'product.productName', csv: true, title: 1, searchable: true },
        { text: 'Batchcode', value: 'batch.batchcode', csv: true, subtitle: 1, searchable: true },
        { text: 'DOM', value: 'batch.dom', textFilter: 'toShortDate', subtitle: 2, prefix: 'DOM: ' },
        { text: 'EXP', value: 'batch.exp', textFilter: 'toShortDate' },
        { text: 'Quantity', value: 'available', breakdown: true, csvBreakdown: true, subtitle: 3, prefix: 'QTY: ' }, //csvProductIDProp: 'batch.productID' },
        { text: 'Wasted', value: 'wasted', textFilter: 'toShortDate', breakdown: true, csvBreakdown: true },
        { text: 'On Hold', value: 'onHold', textFilter: 'toShortDate', breakdown: true, csvBreakdown: true }]},on:{"sourceChanged":_vm.updateBlade},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-toolbar-title',[_c('BT-Entity',{attrs:{"alternateText":"Stocked Items","navigation":"locations","itemValue":_vm.mLocationID,"itemText":"locationName"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('span',[_vm._v("Stocked Items: ")])]},proxy:true}],null,false,4008488035)}),_c('BT-Snack',{model:{value:(_vm.msg),callback:function ($$v) {_vm.msg=$$v},expression:"msg"}})],1)]},proxy:true},{key:"actions",fn:function(ref){
        var items = ref.items;
return [_c('BT-Dialog',{attrs:{"getOnOpenAsync":_vm.getTransfer,"icon":"mdi-transfer","item":items,"label":"Transfer","small":"","title":"Instant Transfer To Another Location","width":"600"},on:{"ok":_vm.postTransfer},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
return [_c('BT-Select-List-Box',{attrs:{"canSearch":false,"isSelecting":"","navigation":"locations","onFilter":function (list) { return list.filter(function (z) { return z.id != item.departureLocationID; }); },"label":"Transfer To","itemText":"locationName","isEditing":""},model:{value:(item.destinationLocationID),callback:function ($$v) {_vm.$set(item, "destinationLocationID", $$v)},expression:"item.destinationLocationID"}}),(item.destinationLocationID != null)?_c('BT-List',{attrs:{"items":item.consignmentEntryItems,"onFilter":_vm.filterEntries,"small":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.product.productName))]),_c('v-list-item-subtitle',[_c('v-row',{staticClass:"ma-0"},[_vm._v(" DOM: "+_vm._s(_vm._f("toShortDate")(item.batch.dom))+" "),_c('v-spacer'),_vm._v(" EXP: "+_vm._s(_vm._f("toShortDate")(item.batch.exp))+" ")],1)],1)],1),_c('v-list-item-action',[_c('BT-Number-Edit',{attrs:{"suffix":'(' + item.available + ')'},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", _vm._n($$v))},expression:"item.quantity"}})],1)]}}],null,true)}):_vm._e()]}}],null,true)}),_c('BT-Stock-Adjustment-Dialog',{attrs:{"hideButton":"","showToggle":_vm.showAdjustmentDialog,"stockItem":_vm.currentStockItem}})]}},{key:"itemActions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openStockAdjustment(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cube-send")])],1)]}}],null,false,1097632346)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }