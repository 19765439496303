<template>
    <BT-Blade-Items
        v-if="mLocationID != null"
        @sourceChanged="updateBlade"
        addBladeName="stock-adjustments"
        :bladesData="bladesData"
        bladeName="stock-items"
        canBreakdown
        :canCreate="false"
        :canEdit="false"
        canSearchLocal
        :getParams="getParams"
        :getSelectBladeData="(bladeData, item) => { return { productID: item.productID, locationID: mLocationID, batchID: item.batchID } }"
        :getSelectQuery="(bladeData, item) => { return { productID: item.productID, locationID: mLocationID, batchID: item.batchID } }"
        :loading="isLoading"
        navigation="stock-items"
        :refreshToggle="refreshToggle"
        title="Stocked Items"
        useServerFilters
        useServerPagination
        :headers="[
            { text: 'Location', value: 'location.locationName', csv: true, title: 0, searchable: true },
            { text: 'Product', value: 'product.productName', csv: true, title: 1, searchable: true },
            { text: 'Batchcode', value: 'batch.batchcode', csv: true, subtitle: 1, searchable: true },
            { text: 'DOM', value: 'batch.dom', textFilter: 'toShortDate', subtitle: 2, prefix: 'DOM: ' },
            { text: 'EXP', value: 'batch.exp', textFilter: 'toShortDate' },
            { text: 'Quantity', value: 'available', breakdown: true, csvBreakdown: true, subtitle: 3, prefix: 'QTY: ' }, //csvProductIDProp: 'batch.productID' },
            { text: 'Wasted', value: 'wasted', textFilter: 'toShortDate', breakdown: true, csvBreakdown: true },
            { text: 'On Hold', value: 'onHold', textFilter: 'toShortDate', breakdown: true, csvBreakdown: true }]">
        <template v-slot:toolbar>
            <v-toolbar-title>
                <BT-Entity
                    alternateText="Stocked Items"
                    navigation="locations"
                    :itemValue="mLocationID"
                    itemText="locationName">
                    <template v-slot:prepend><span>Stocked Items: </span></template>
                </BT-Entity>
                <BT-Snack v-model="msg" />
            </v-toolbar-title>
        </template>
        <template v-slot:actions="{ items }">
            <BT-Dialog
                
                @ok="postTransfer"
                :getOnOpenAsync="getTransfer"
                icon="mdi-transfer"
                :item="items"
                label="Transfer"
                small
                title="Instant Transfer To Another Location"
                width="600">
                <template v-slot="{ item }">
                    <BT-Select-List-Box
                        :canSearch="false"
                        isSelecting
                        navigation="locations"
                        :onFilter="list => list.filter(z => z.id != item.departureLocationID)"
                        v-model="item.destinationLocationID"
                        label="Transfer To"
                        itemText="locationName"
                        isEditing />
                    
                    <BT-List
                        v-if="item.destinationLocationID != null"
                        :items="item.consignmentEntryItems"
                        :onFilter="filterEntries"
                        small>
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.product.productName }}</v-list-item-title>
                                <v-list-item-subtitle>
                                    <v-row class="ma-0">
                                        DOM: {{ item.batch.dom | toShortDate }}
                                        <v-spacer />
                                        EXP: {{ item.batch.exp | toShortDate }}
                                    </v-row>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <BT-Number-Edit
                                    v-model.number="item.quantity"
                                    :suffix="'(' + item.available + ')'" />
                            </v-list-item-action>
                        </template>
                    </BT-List>

                </template>
            </BT-Dialog>
            <BT-Stock-Adjustment-Dialog
                hideButton
                :showToggle="showAdjustmentDialog"
                :stockItem="currentStockItem" />
        </template>
        <template v-slot:itemActions="{ item }">
            <v-btn small icon @click.stop="openStockAdjustment(item)">
                <v-icon small>mdi-cube-send</v-icon>
            </v-btn>
        </template>
    </BT-Blade-Items>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Stock-Items-Blade',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue'),
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
        BTStockAdjustmentDialog: () => import('~components/BT-Stock-Adjustment-Dialog.vue')
    },
    data: function() {
        return {
            currentStockItem: null,
            isLoading: false,
            mLocationID: null,
            msg: null,
            refreshToggle: false,
            showAdjustmentDialog: false
        }
    },
    props: {
        bladesData: null
    },
    async mounted() {
        if (this.mLocationID == null) {
            this.mLocationID = this.selectedLocationID();

            if (this.mLocationID == null) {
                this.mLocationID = await this.$selectItem({ 
                    navigation: 'locations', 
                    itemText: 'locationName', 
                    itemValue: 'id',
                    required: true 
                });
            }
        }
    },
    methods: {
        filterEntries(list) {
            list.sort(firstBy(z => z.product.sortNumber).thenBy(z => z.batch.exp));
            return list;
        },
        getTransfer(entries) {
            return {
                destinationLocationID: null,
                departureLocationID: this.mLocationID,
                consignmentEntryItems: entries.filter(z => z.batch != null && z.product != null).map(x => {
                    return {
                        batchID: x.batchID,
                        batch: x.batch,
                        productID: x.productID,
                        product: x.product,
                        available: x.available,
                        quantity: 0
                    }
                })
            }
        },
        getParams(bladeData) {
            return {
                includeDetails: false,
                locationID: this.mLocationID || bladeData.data.locationID
            }
        },
        openStockAdjustment(stockItem) {
            this.currentStockItem = stockItem;
            this.showAdjustmentDialog = !this.showAdjustmentDialog;
        },
        async postTransfer(transfer) {
            try {
                this.isLoading = true;
                await this.$BlitzIt.store.post('instant-transfers', transfer, null, null);
                this.refreshToggle = !this.refreshToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        updateBlade(bladeData) {
            if (bladeData != null) {
                if (bladeData.data.locationID != null) {
                    console.log('ss');
                    this.mLocationID = bladeData.data.locationID;
                }
            }
        }
    }
}
</script>